import "@capterra/arubaito/dist/utility.min.css";
import "@capterra/arubaito/dist/arubaito_component.min.css";
import "swagger-ui-react/swagger-ui.css";
import { Suspense, lazy } from "react";
import { ClientOnly } from "~/components/ClientOnly";
import { UserDataProvider } from "~/context/UserData";
import { LoadingOverlay } from "~/components/Loader";
import { useCurrentUser } from "app/hooks/useCurrentUser";

const App = lazy(() => import("~/app/index"));

export default function LegacyApp() {
  const session = useCurrentUser();

  return (
    <UserDataProvider value={session}>
      <Suspense fallback={<LoadingOverlay />}>
        <ClientOnly>{() => <App />}</ClientOnly>
      </Suspense>
    </UserDataProvider>
  );
}
